﻿const { client: sanityClient } = require('../../utils/sanity/index.js');
import { card } from '../../_includes/_partials/events.js';

import {
  projection,
  eventWithUrl,
  withTicketUrl
} from '../../$content/events.js';

const sanity = sanityClient.withConfig({
  useCdn: true,
  useCredentials: false,
  token: false
});

const icons = {
  currency: new URL(`/assets/svgs/currency.svg`, import.meta.url)
};

export const init = (Alpine) => {
  Alpine.data('eventFeed', component);
};

export const component = (query, { showPrivate = false } = {}) => ({
  events: [],
  async init() {
    this.events = await sanity
      .fetch(
        `{
          "events": *[_type == "event" && ${
            !showPrivate
              ? `!references(*[_type == "metadata.audience" && private]._id) && `
              : ``
          }(type != "_instance" || (type == "_instance" && defined(parent->))) && (
            (
              defined(end) && dateTime(end) >= dateTime(now())
            ) || (
              defined(start) && dateTime(start) > (dateTime(now()) - (60 * 60 * 24 * 7))
            ) 
          ) && !(type in ["exhibition","exhibition-touring","cinema-program"])]{
            ...parent->,
            ...
          }{
            ...,
            "type": coalesce(parent->type, type),
            !defined(end) && defined(films) => {
              "end": coalesce(end, dateTime(start) + (math::sum(films[]->runtime) * 60))
            }
          }[dateTime(end) >= dateTime(now())]
        }${
          query ? `{ "events": events[${query}] }` : ``
        }.events|order(start asc, coalesce(priority, 0) desc, end asc, title asc)[0...4]${projection}`,
        {
          today: new Date(new Date().setHours(0, 0, 0, 0)).toISOString()
        }
      )
      .then((events) => {
        const externalEvents = events.reduce((externalEvents, event, i) => {
          if (event.externalEvent?.id) {
            const event = events[i];
            const { id, type } = event.externalEvent;

            if (!externalEvents[type]) externalEvents[type] = [];

            externalEvents[type].push(id);

            new Promise((resolve) => {
              event.resolve = resolve;
            }).then(
              (response) => (
                Object.assign(event.externalEvent, response),
                Object.assign(event, {
                  tickets:
                    response?.ticket_types ||
                    response?.[0]?.ticket_classes.map(({ cost, ...rest }) => ({
                      price: cost?.value / 100 || 0,
                      ...rest
                    })),
                  is_sold_out:
                    response?.[0]?.ticket_availability.is_sold_out ||
                    event?.is_sold_out,
                  waitlist_available: response?.[0]?.ticket_availability?.waitlist_available ||
                    event?.waitlist_available
                })
              )
            );
          }

          return externalEvents;
        }, {});

        if (Object.keys(externalEvents).length) {
          return fetch(
            new URL(
              `/_/events/ticketing/?` + new URLSearchParams(externalEvents),
              location.origin
            )
          )
            .then((r) => r.json())
            .then((response) =>
              events.forEach((event) => {
                let resolved =
                  event.resolve &&
                  response.find(({ id }) => event.externalEvent.id == id);

                if (resolved) event.resolve(resolved);
              })
            )
            .then(() => events);
        } else {
          return events;
        }
      });
  },
  card(event) {
    return {
      ['x-html']() {
        return card({
          ...withTicketUrl(eventWithUrl(event)),
          wrap: false,
          icons
        });
      }
    };
  }
});
